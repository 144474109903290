import React, { useContext } from "react";
import "./sertif.css";
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css";
import Google from "../../img/googledata.png";
import GoogleR from "../../img/WithR.png";
import IBM from "../../img/IBMData.png";
import DeepL from "../../img/DeepL.png";
import ITSupport from "../../img/ITSupport.PNG";
import ML from "../../img/ML.PNG";
import Docker from "../../img/Docker.PNG";
import Django from "../../img/Django.PNG";
import Second from "../../img/Second.PNG";
import { themeContext } from "../../Context";
const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const link = (url) =>{
    window.location.href = url
  }
  return (
    <div className="sertif" id="sertif">
      {/* heading */}
      <span style={{color: darkMode?'white': ''}}>My</span>
      <span>Certificates</span>

      {/* slider */}
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        <SwiperSlide>
          <img src={Google} alt="Data Analytics Certificate" onClick={()=> link('https://drive.google.com/file/d/1XMh_FOV45DiqThdEte78IASJVs0cu4Ld/view?usp=sharing')}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={ML} alt="" onClick={()=> link('https://drive.google.com/file/d/1fMOcnac7ULIpyTVa61XkUVYFrFLVeTAl/view?usp=sharing')}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={ITSupport} alt="" onClick={()=> link('https://drive.google.com/file/d/1R0FZLHn_P5LpiLmkdg-_fzXDpM17CmNj/view?usp=sharing')}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={Second} alt="" onClick={()=> link('https://drive.google.com/file/d/1OV5g-_raT4il7sGzET96L4bOJP59i80n/view?usp=sharing')}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={Docker} alt="" onClick={()=> link('https://drive.google.com/file/d/1MoTAFIPNGXqYl1JRbKQjcsePZJx4yafM/view?usp=sharing')}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={GoogleR} alt="" onClick={()=> link('https://drive.google.com/file/d/1ge10hMpCIBYCMfNJcIqaWkgKh4_L3KTu/view?usp=sharing')}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={IBM} alt="" onClick={()=> link('https://drive.google.com/file/d/1ZYLgmZwqaaCjfvHrxz9Xd5e4b15_WN5I/view?usp=sharing')}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={DeepL} alt="" onClick={()=> link('https://drive.google.com/file/d/1lPKNj1F-flFrDKgoeYPu88wVwgh6kfol/view')}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={Django} alt="" onClick={()=> link('https://drive.google.com/file/d/1tb4qkvLIL2-Rjx16j1LF7MacRLREqYdO/view?usp=sharing')}/>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Portfolio;
