import React, { useContext } from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css";
import Makanan from "../../img/Makanan.png";
import portfolio from "../../img/portfolioo.png";
import Gesture from "../../img/gesture.png";
import Github from "../../img/githubleon.png";
import GuiPython from "../../img/GUIPython.png";
import HelpMedic from "../../img/help-medic.PNG";
import { themeContext } from "../../Context";
const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const link = (url) =>{
    window.location.href = url
  }
  return (
    <div className="portfolio" id="portfolio">
      {/* heading */}
      <span style={{color: darkMode?'white': ''}}>Recent Projects</span>
      <span>Portfolio</span>

      {/* slider */}
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        <SwiperSlide>
          <img src={HelpMedic} alt=""  onClick={()=> link('https://github.com/Leon24k/pemesananMakanan')}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={Makanan} alt=""  onClick={()=> link('https://github.com/Leon24k/pemesananMakanan')}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={GuiPython} alt="" onClick={()=> link('https://github.com/Leon24k/FinalProject-AdvancedProgramming')}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={portfolio} alt=""  onClick={()=> link('https://rivo-rumadi.netlify.app/')}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={Gesture} alt="" onClick={()=> link('https://github.com/Leon24k/Hand-Gesture')}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={Github} alt="" onClick={()=> link('https://github.com/Leon24k')}/>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Portfolio;
