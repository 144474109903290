import React, { useContext } from "react";
import "./Works.css";
import Spotify from "../../img/spotify.png";
import Vscode from "../../img/vscode.png";
import Face from "../../img/muka.png";
import Lr from "../../img/lr.png";
import Pycharm from "../../img/pycharm.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import {Link} from 'react-scroll'
//make link spotify
const link = (url) =>{
  window.location.href = url
}
const Works = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  return (
    <div className="works" id="works">
      {/* left side */}
      <div className="w-left">
        <div className="awesome">
          {/* dark Mode */}
          <span style={{ color: darkMode ? "white" : "" }}>
            About Me
          </span>
          <span>My Tools & Stuff</span>
          <spane>
          I usually use visual studio code for my daily app code. 
            <br />
            And love to learn music on Spotify to relax and help me enjoy coding.
            <br />
            Dont forget to follow my spotify :) (just click on it)
            <br />
            Also, use Pycharm as my 2nd daily driver for python. 
            I use it to do some analysis of data.
            <br />
            I'm using Lightroom to enhance my photos.
          </spane>
          <Link to="contact" smooth={true} spy={true}>
            <button className="button s-button">Work With Me</button>
          </Link>
          <div
            className="blur s-blur1"
            style={{ background: "#ABF1FF94" }}
          ></div>
        </div>

        {/* right side */}
      </div>
      <div className="w-right">
        <motion.div
          initial={{ rotate: 45 }}
          whileInView={{ rotate: 0 }}
          viewport={{ margin: "-40px" }}
          transition={{ duration: 3.5, type: "spring" }}
          className="w-mainCircle"
        >
          <div className="w-secCircle2">
            <img src={Spotify} alt="" onClick={()=> link('https://open.spotify.com/user/o2uu1r7wlry388vv9ja71fj8z?si=19a3b047b6fa4e0c')}/>
          </div>
          <div className="w-secCircle">
            <img src={Vscode} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={Face} alt="" />
          </div>{" "}
          <div className="w-secCircle">
            <img src={Lr} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={Pycharm} alt="" />
          </div>
        </motion.div>
        {/* background Circles */}
        <div className="w-backCircle blueCircle"></div>
        <div className="w-backCircle yellowCircle"></div>
      </div>
    </div>
  );
};

export default Works;
