import React, { useContext, useRef, useState, useEffect } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { themeContext } from "../../Context";

const MAX_MESSAGES_PER_DAY = 3; // Set the maximum number of messages per day

const Contact = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  const form = useRef();
  const [done, setDone] = useState(false);
  const [messagesData, setMessagesData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Retrieve the stored value of messagesData from local storage or cookies
    const storedMessagesData = localStorage.getItem("messagesData");
    if (storedMessagesData) {
      setMessagesData(JSON.parse(storedMessagesData));
    }
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Get the current date
    const currentDate = new Date().toISOString().split("T")[0];

    // Filter messages sent on the current date
    const messagesSentToday = messagesData.filter(
      (message) => message.date === currentDate
    );

    if (messagesSentToday.length >= MAX_MESSAGES_PER_DAY) {
      // Display a popup when the limit is reached
      setShowPopup(true);
      return;
    }

    emailjs
      .sendForm(
        "service_spvt0ou",
        "template_9jgk1j5",
        form.current,
        "s0YbBpZonavAs1xa1"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
          form.current.reset();

          // Update messagesData with the new message and date
          const updatedMessagesData = [
            ...messagesData,
            { date: currentDate, time: new Date().toLocaleTimeString() },
          ];

          setMessagesData(updatedMessagesData);
          localStorage.setItem(
            "messagesData",
            JSON.stringify(updatedMessagesData)
          );
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="contact-form" id="contact">
      <div className="w-left">
        <div className="awesome">
          <span style={{ color: darkMode ? "white" : "" }}>Get in Touch</span>
          <span>Work With Me</span>
          <div className="bold">
            <span>Also, you can contact me via the form below</span>
          </div>
          <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
        </div>
      </div>
      <div className="c-right">
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="user_name" className="user" placeholder="Name" />
          <input type="email" name="user_email" className="user" placeholder="Email" />
          <textarea name="message" className="user" placeholder="Type your Message" />
          <input type="submit" value="Send" className="button" />
          <span>{done && "Thanks for Contacting me"}</span>
          {showPopup && (
            <div className="popup">
              <p>You have reached the maximum limit of messages for today.</p>
              <button onClick={closePopup}>Close</button>
            </div>
          )}
          <div className="blur c-blur1" style={{ background: "var(--purple)" }}></div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
